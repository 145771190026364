import React from 'react'

import Layout from '../components/layout'
import Container from '../components/container'
import BottomHero from '../components/bottomHero'

import {
  Flex,
  Box,
  Text,
  Image
} from 'rebass'

import causesInfographic from '../images/causes_infographic.png'
import howReversityWorks from '../images/how_reversity_works.png'
import diabetesStatistics from '../images/diabetes_statistics.png'

const FAQPage = () => (
  <Layout>
    <Container>
      {/* Hero */}
      <Flex flexWrap='wrap' py={3}>
        <Box mx='auto'>
          <Text textAlign='center' fontWeight='bold' py={2} fontSize={5}>Frequently Asked Questions</Text>
        </Box>
      </Flex>

      {/* Infographics */}
      <Flex p={3}>
        <Box mx='auto'>
          <Text fontSize={4} fontWeight='bold'>What are the causes of diabetes?</Text>
          <Image src={causesInfographic} alt='Causes of diabetes' />
        </Box>
      </Flex>

      <Flex p={3}>
        <Box mx='auto'>
          <Text fontSize={4} fontWeight='bold'>How many adults does diabetes affect in the U.S.?</Text>
          <Image src={diabetesStatistics} alt='Diabetes statistics' />
        </Box>
      </Flex>

      <Flex p={3}>
        <Box mx='auto'>
          <Text fontSize={4} fontWeight='bold'>How does Reversity work?</Text>
          <Image src={howReversityWorks} alt='How Reversity works' />
        </Box>
      </Flex>
    </Container>
    <BottomHero bg='lightGray' />
  </Layout>
)

export default FAQPage
